<template>
  <div>
    <div class="label" v-if="useLabel && label !== ''">{{ label }} <span class="require" v-if="isRequire">*</span></div>
    <div class="position-relative img-uploader p-border-brand flex-center" :style="viewStyles">
      <input
        class="imgUploader-input"
        v-if="!file"
        type="file"
        accept="image/jpeg, image/png"
        @click.stop
        :id="uid"
        @change="onFileChange"
        multiple="multiple"
      />
      <label class="imgUploader-box unselect" v-if="!file" @click.stop :for="uid">
        <!-- :style="boxStyles"> -->
        <i class="material-icons">add</i>
      </label>

      <div v-if="file && file !== ''" style="position: relative; width: 100%; height: 100%;">
        <!-- 닫기버튼 -->
        <i
          v-if="file && file !== '' && isImgDeletable"
          class="unselect imgUploader-close material-icons"
          :style="closeStyles"
          @click.stop.prevent="deleteImg"
          >close</i
        >
      </div>
      <div v-if="countLabel && index === 0 && file === ''" class="count-label body4 sub4">
        {{ files.length - 1 }} / {{ limit }}
      </div>
    </div>
  </div>
</template>

<script>
import ImageUploaderMixin from '../../mixins/ImageUploaderMixin';

export default {
  name: 'InputImgUploader',
  props: {
    useLabel: {
      type: Boolean,
      default: true,
    },
    isRequire: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Array],
    },
    files: {
      type: [String, Array],
    },
    index: {
      type: Number,
    },
    limit: {
      type: Number,
      default: 5,
    },
    isImgDeletable: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 120,
    },
    height: {
      type: Number,
      default: 120,
    },
    countLabel: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [ImageUploaderMixin],
  components: {},
  created() {
    this.uid = this.uuidv4();
    if (this.value === '') {
      this.file = '';
    } else {
      this.file = this.value;
    }
  },
  data() {
    return {
      uid: '',
      file: undefined,
      imageArray: [],
    };
  },
  methods: {
    async onFileChange(e) {
      this.$store.commit('setLoading', true);
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.$store.commit('setLoading', false);
        return;
      }

      let length = Object.keys(files).length;

      if (length === 1) {
        let res = await this.loadFile(files[0]);
        this.$emit('update:value', res.target.result);
        this.$store.commit('setLoading', false);
      } else {
        let result = [];
        for (let j = 0; j < length; j++) {
          let res = await this.loadFile(files[j]);
          result.push(res.target.result);
        }
        this.$emit('update:value', result);
        this.$store.commit('setLoading', false);
      }
    },
    async loadFile(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (f) => {
          resolve(f);
        };
      });
    },

    sloadFile(file, length, index) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (f) => {
          let params = {
            f: f,
            file: file,
            length: length,
            index: index,
          };
          resolve(params);
        };
      })
        .then((params) => {
          return this.loadImage(params.f, params.file, params.length, params.index);
        })
        .catch((err) => {
          this.$store.commit('setLoading', false);
          console.log(err);
        });
    },
    loadImage(f, file, length, index) {
      return new Promise((resolve) => {
        const image = new Image();
        image.onload = () => {
          let params = {
            file: file,
            image: image,
            length: length,
            index: index,
          };
          resolve(params);
        };
        image.src = f.target.result;
      })
        .then((params) => {
          let afterAction = function(file, resizeImg, length) {
            let self = this;
            self.imageArray.push(resizeImg);
            if (length === self.imageArray.length) {
              if (length === 1) self.file = self.imageArray[0];
              else self.file = self.imageArray;
              self.$emit('update:value', self.file);
              self.imageArray = [];
            }
          }.bind(this);

          this.$store.commit('setLoading', false);
          return this.getFixedImage(params.file, params.image, params.length, params.index, afterAction);
          // return this.getFixedImage(params.file, params.image, params.length, params.index);
        })
        .catch((err) => {
          this.$store.commit('setLoading', false);
          console.log(err);
        });
    },
    deleteImg() {
      if (this.file && this.file !== '') {
        this.file = '';
        this.$emit('delImg', this.index);
      }
    },
  },
  computed: {
    closeStyles() {
      return {};
    },
    viewStyles() {
      return {
        backgroundImage: 'url(' + this.file + ')',
        width: this.width + 'px',
        height: this.height + 'px',
      };
    },
  },
  watch: {
    value(n) {
      if (n === '') {
        this.file = '';
      } else {
        this.file = this.image(n);
      }
      // if (Array.isArray(this.file)) {
      //   this.file = n;
      // } else {
      //   this.file = this.image(n);
      // }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.img-uploader
  margin-right 8px
  border-radius 8px
  border 1px solid $gray1
  background-size contain
  background-repeat no-repeat
  background-position center

.imgUploader-input
  position fixed
  top -20px
  left -20px
  width 0
  height 0

.imgUploader-box
  display flex
  justify-content center
  align-items center
  text-align center
  width 100%
  height 100%
  border 1px solid $gray1
  border-radius 8px
  i
    font-size 24px
    color $sub3
.imgUploader-close
  cursor pointer
  color white
  background-color $sub2
  border 1px solid white
  padding 3px
  font-size 14px
  text-align center
  position absolute
  top 4px
  right 4px
  z-index 10
  border-radius 100%

.count-label
  position absolute
  bottom 4px
  right 4px
</style>
